@import './variables';
@import './functions';
@import './mixins';

.info,
.infoHome {
  background-color: palette(primary, base);
  grid-area: Info;
  padding: 1rem;
  @include textColor(palette(primary, base));
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.infoHome {
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.info {
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;

  .player {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;

    #doctor {
      width: 3.5rem;
      margin-right: 1.2rem;
      flex-shrink: 0;
    }

    input.changeName {
      border: none;
      font-size: 1rem;
      max-width: 10rem;
      min-width: 5rem;
    }

    .name {
      cursor: text;
    }
  }
}
