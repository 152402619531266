@import './variables';
@import './functions';
@import './mixins';

.userOptions {
  display: flex;
  flex-direction: column;
  align-items: center;

  select {
    appearance: none;
    background-color: palette(primary, xx-light);
    border: none;
    border-radius: 50%;
    padding: 0.2rem;
    outline: none;
    cursor: pointer;

    font-size: 2rem;
  }
}
