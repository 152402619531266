//************* Colors
$white: #fff;
$black: #040f0f;
$grey-light: #dce0d9;
$grey-dark: #2d3142;

$prune: #6b0f1a;
$persianGreen: #03b5aa;

$color-primary: $prune;
$color-secondary: $persianGreen;

$palettes: (
  white: (
    base: $white,
    dark: darken($white, 20%),
  ),
  grey: (
    xx-light: lighten($grey-light, 12%),
    x-light: lighten($grey-light, 10%),
    light: $grey-light,
    base: $grey-dark,
    dark: darken($grey-dark, 10%),
  ),
  black: (
    light: lighten($black, 20%),
    base: $black,
    dark: darken($black, 20%),
  ),
  primary: (
    xx-light: lighten($color-primary, 70%),
    x-light: lighten($color-primary, 20%),
    light: lighten($color-primary, 12%),
    base: $color-primary,
    dark: darken($color-primary, 12%),
    x-dark: darken($color-primary, 20%),
  ),
  secondary: (
    xx-light: lighten($color-secondary, 50%),
    x-light: lighten($color-secondary, 20%),
    light: lighten($color-secondary, 12%),
    base: $color-secondary,
    dark: darken($color-secondary, 12%),
    x-dark: darken($color-secondary, 20%),
  ),
);

//************* Fonts
$fontTitles: 'Roboto Mono', monospace;
$fontText: 'Rubik', sans-serif;
$cardRadius: 1rem;
$radius: 1.5rem;
$shadow: 0 0 0.25rem palette(grey, base);
$HandCardShadowXS: 0.1rem 0 0 0.01rem palette(primary, dark);
$HandCardShadowM: 0 0.125rem 0.25rem palette(primary, dark);
$HandCardShadowMHover: 0 0.25rem 0.375rem palette(primary, dark);
