@import './variables';
@import './functions';
@import './mixins';

.roundCard {
  background-color: palette(primary, base);
  grid-area: Header;
  font-family: $fontTitles;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  font-size: 1.3rem;
  @include textColor(palette(primary, base));

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
  }
  @media screen and (min-width: 769px) {
    flex-direction: row;
    align-items: center;
    padding: 0 0 0 1rem;
  }

  h2 {
    @media screen and (max-width: 768px) {
      width: 95%;
      font-size: 1.6rem;
    }
    @media screen and (min-width: 769px) {
      width: 95%;
      font-size: 1.3rem;
    }
  }

  .wrapRoundCard {
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
    @media screen and (min-width: 769px) {
      width: 68%;
      justify-content: flex-start;
    }
  }

  .navigation {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0.7rem 0;
    }
    @media screen and (min-width: 769px) {
      width: 32%;
      margin: 0;
    }

    .nav1 {
      display: flex;
      flex-direction: row;
    }

    .nav2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      button {
        @media screen and (max-width: 768px) {
          height: 4rem;
          width: 80%;
          margin: 0.5rem 0;
        }
        @media screen and (min-width: 769px) {
          height: 2rem;
          width: 100%;
          margin: 0.2rem 0;
        }
      }
    }

    button {
      font-family: $fontTitles;
      font-size: 1rem;
      height: 2rem;
      width: 100%;
      background: palette(secondary, base);
      @include textColor(palette(secondary, base));
      border-radius: $cardRadius;
      cursor: pointer;
      outline: none;
      border: none;
      box-shadow: $shadow;
      transition: 400ms ease all;

      &:hover {
        background: palette(secondary, dark);
      }

      &.roundBtn {
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;

        &:hover {
          #rightArrow {
            padding-left: 0.5rem;
          }
          #leftArrow {
            padding-right: 0.5rem;
          }
        }
      }

      svg {
        width: 75%;
        transition: 200ms ease-in all;

        &#leftArrow {
          padding-right: 0.3rem;
        }
        &#rightArrow {
          padding-left: 0.3rem;
        }
      }
    }
  }
}
