.cookieBannerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(black, 0.75);
}

.cookieBanner {
  position: fixed;
  bottom: 0;
  width: calc(90vw - 2rem);
  min-width: 8.5rem;
  max-width: 20rem;
  min-height: 15rem;
  max-height: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding: 1rem;

  @media screen and (max-width: 768px) {
    right: 1rem;
  }
  @media screen and (min-width: 769px) {
    right: 3rem;
  }

  p {
    font-family: $fontText;
    overflow-y: scroll;
    text-align: justify;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    button {
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      width: 45%;
      cursor: pointer;
      font-family: $fontText;
      border: none;
      background-color: palette(secondary, base);
      @include textColor(palette(secondary, base));
      transition: 500ms ease all;
      outline: none;
      min-width: 8rem;
      margin: 0.25rem;

      &:hover {
        background-color: palette(secondary, dark);
        @include textColor(palette(secondary, dark));
      }
    }
  }
}
