/******************************/
/******** CREDITS PART ***********/
/******************************/

.credits {
  font-size: 0.8rem;
  font-style: italic;
  letter-spacing: 0.1rem;

  a {
    color: palette(white, base);
    transition: 400ms all ease;
    margin: 0;

    &:hover {
      text-decoration: none;
    }
  }
}
