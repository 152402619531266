@import './variables';
@import './functions';
@import './mixins';

/******************************/
/******** HOME PART ***********/
/******************************/

.boardHome {
  background-color: palette(grey, xx-light);
  grid-area: Board;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include textColor(palette(grey, xx-light));

  $radius: 1.5rem;

  .btnWrapper {
    width: 47%;
    min-width: 19rem;
    min-height: 25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    background-color: palette(primary, xx-light);
    border-radius: $radius;
    box-shadow: $shadow;

    @media screen and (max-width: 768px) {
      margin: 1rem 0;
      height: 50%;
    }
    @media screen and (min-width: 769px) {
      margin: 0;
      height: 70%;
    }

    .gameOptions {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;

      select {
        font-size: 1rem;
        padding: 0.5rem;
      }
    }

    button {
      width: 100%;
      height: 8rem;
      font-family: $fontTitles;
      font-size: 2rem;
      cursor: pointer;
      outline: none;
      background-color: palette(primary, base);
      border: none;
      @include textColor(palette(primary, base));
      transition: ease-in-out all 500ms;
      border-radius: 0 0 $radius $radius;
      margin: 0;

      &:hover {
        background-color: palette(primary, dark);
        border: none;
        @include textColor(palette(primary, dark));
      }
    }

    $virusSize: 14rem;

    svg#virus {
      width: $virusSize;
      height: $virusSize;
    }
  }
}

.banner {
  align-self: flex-end;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.1rem;
  background-color: palette(secondary, base);
  @include textColor(palette(secondary, base));

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 769px) {
    width: 80%;
  }

  p {
    padding: 0.3rem 0.5rem;
    margin: 0;
    text-align: center;
  }

  .emojis {
    font-size: 1.5rem;
  }
}

/******************************/
/******** WAITING PART ***********/
/******************************/

.boardWait {
  background-color: palette(grey, xx-light);
  grid-area: Board;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include textColor(palette(grey, xx-light));

  $radius: 1.5rem;

  @media screen and (min-width: 769px) {
    overflow-y: auto;
  }

  .rules {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (min-width: 769px) {
      width: 52%;
      overflow-y: auto;
    }

    p {
      padding: 0.5rem 1rem;
    }
    ul {
      margin: 0;
    }
  }

  .roundBtn {
    background: palette(secondary, base);
    @include textColor(palette(secondary, base));
    border-radius: $cardRadius;
    outline: none;
    border: none;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
    padding: 0;
    margin: 0 0.5rem;

    svg {
      width: 75%;

      &#rightArrow {
        padding-left: 0.1rem;
        padding-top: 0.1rem;
      }
    }
  }

  .btnWrapper {
    width: 34%;
    min-width: 19rem;
    min-height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: palette(primary, xx-light);
    border-radius: $radius;
    box-shadow: $shadow;

    @media screen and (max-width: 768px) {
      margin: 1rem 0;
      height: 50%;
    }
    @media screen and (min-width: 769px) {
      margin: 1rem 0;
      height: 70%;
    }

    .actionSectionWrapper {
      width: 100%;
      flex-grow: 7;
    }

    button {
      flex-grow: 3;
      min-height: 5rem;
      width: 100%;
      font-family: $fontTitles;
      font-size: 2rem;
      cursor: pointer;
      outline: none;
      background-color: palette(primary, base);
      border: none;
      @include textColor(palette(primary, base));
      transition: ease-in-out all 500ms;
      border-radius: 0 0 $radius $radius;
      margin: 0;

      &:hover {
        background-color: palette(primary, dark);
        border: none;
        @include textColor(palette(primary, dark));
      }
    }
  }
}

/******************************/
/******** PLAY PART ***********/
/******************************/

.boardPlay {
  overflow-y: auto;
  grid-area: Board;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  ul.bunchOfCards {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
  }
  .navBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 5rem;
    flex-wrap: wrap;

    .roundBtn {
      background: palette(secondary, base);
      cursor: pointer;
      outline: none;
      border: none;
      box-shadow: $shadow;
      transition: 400ms ease all;
      margin: 1rem;
      border-radius: 50%;
      height: 3rem;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      &:hover {
        background: palette(secondary, dark);
        #rightArrow {
          padding-left: 0.5rem;
        }
        #leftArrow {
          padding-right: 0.5rem;
        }
      }

      &:disabled {
        cursor: default;
        opacity: 0.6;
        background: palette(grey, dark);
      }

      svg {
        width: 75%;
        transition: 200ms ease-in all;
        &#leftArrow {
          padding-right: 0.3rem;
        }
        &#rightArrow {
          padding-left: 0.3rem;
        }
      }
    }
  }
}

/******************************/
/******** RESULTS PART ***********/
/******************************/

.resultWrap {
  grid-area: Board;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.resultBoard {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

/******************************/
/******** ERROR PART ***********/
/******************************/

.boardError {
  background-color: palette(grey, xx-light);
  grid-area: Board;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  @include textColor(palette(grey, xx-light));

  .errorWrap {
    width: 85%;
    p {
      font-size: 1.5rem;
      width: 100%;
      text-align: center;
      padding-bottom: 0.5rem;
      word-wrap: break-word;
    }
  }

  #medical {
    width: 10rem;
    margin: 2rem 0;
  }

  .btnWrapper {
    width: 27%;
    margin: 1rem 0;
    height: 50%;
    min-width: 19rem;
    min-height: 23rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    background-color: palette(primary, xx-light);
    border-radius: $radius;

    button {
      height: 25%;
      min-height: 5rem;
      width: 100%;
      font-family: $fontTitles;
      font-size: 1.4rem;
      cursor: pointer;
      outline: none;
      background-color: palette(primary, base);
      border: none;
      @include textColor(palette(primary, base));
      transition: ease-in-out all 500ms;
      border-radius: 0 0 $radius $radius;
      margin: 0;

      &:hover {
        background-color: palette(primary, dark);
        border: none;
        @include textColor(palette(primary, dark));
      }
    }
  }
}
