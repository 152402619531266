@import './variables';
@import './functions';
@import './mixins';

/******************************/
/******** HAND PART ***********/
/******************************/
.handCard {
  padding: 0;
  margin: 0;
  height: 20%;
  opacity: 1;
  min-height: 5.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  box-shadow: $HandCardShadowM;

  @media screen and (min-width: 769px) {
    &:hover {
      box-shadow: $HandCardShadowMHover;
      span {
        padding-left: 1rem;
      }

      .virusMini {
        width: 2rem;
      }
      .arrow {
        width: 3rem;
      }
    }
  }

  span {
    width: 70%;
    padding-left: 1.5rem;
    font-size: 1.3rem;
    transition: 500ms ease-out all;
  }
  .virusMini {
    width: 0;
    position: absolute;
    top: 10%;
    right: 15%;
    transition: 400ms ease-out all;
    transition-delay: 300ms;
  }
  .arrow {
    width: 0;
    transition: 400ms ease-out all;
  }
}

.playerListItem {
  padding: 0;
  margin: 0;
  height: 20%;
  min-height: 5.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    width: 50%;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-content: center;

    &:nth-child(odd) {
      box-shadow: $HandCardShadowXS;
      width: calc(50% - 0.15rem);
    }
    span {
      padding-left: 0;
      width: 85%;
    }
  }
  @media screen and (min-width: 769px) {
    width: 100%;
    box-shadow: $HandCardShadowM;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    span {
      padding-left: 1.5rem;
      width: 100%;
    }
  }

  span {
    font-size: 1.3rem;
    transition: 500ms ease-out all;
  }
}

/******************************/
/******** BOARD PART ***********/
/******************************/

.boardCard {
  width: calc(100% / 3 - 3%);
  max-width: 15rem;
  min-width: 19rem;
  margin: 1rem;
  height: 23rem;
  min-height: 17rem;
  box-shadow: $shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $cardRadius;

  div {
    border-radius: $cardRadius;
    width: 100%;
    height: 100%;
  }
  .cardHeader {
    height: 20%;
    width: 100%;
    background: palette(secondary, base);
    @include textColor(palette(secondary, base));
    border-radius: $cardRadius $cardRadius 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      padding-left: 1rem;
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
  .cardContent {
    height: 80%;
    width: 100%;
    border-radius: 0 0 $cardRadius $cardRadius;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1.2rem;
      text-align: center;
      transition: 500ms ease-in all;
      width: 95%;
    }
  }
}

.myCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.hiddenPlayedCard {
  background: palette(secondary, base);
  display: flex;
  justify-content: center;
  align-items: center;

  #virusShield {
    display: block;
    width: 80%;
    height: auto;
  }
}

.notPlayedCard {
  background: palette(secondary, base);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;

  #quarantine {
    display: block;
    width: 90%;
    height: auto;
    fill: grey;
  }
}

.playedCard {
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
    .voteOverlay {
      left: 0;
      top: 0;
      opacity: 1;
    }
  }

  .voteOverlay {
    position: absolute;
    left: 0;
    top: 100%;
    opacity: 0;
    transition: 400ms ease-in all;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    background: palette(white, base);
    width: 100%;
    height: 100%;
    padding: 0;

    .voteBtn {
      height: 20%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      border-radius: unset;
      transition: 300ms all ease-in;
      width: 100%;

      &:hover {
        background: palette(primary, x-light);
        @include textColor(palette(primary, x-light));
      }

      span {
        font-size: 1.7rem;
        padding: 0 0.5rem;
      }
    }
  }
}

.votedCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  border-radius: $cardRadius;

  .cardContent {
    height: 60%;
  }

  .votesForThisCard {
    height: 20%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .vote {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 1.7rem;
      margin: 0 1rem;
    }
  }

  &.first {
    box-shadow: 0 0 0.7rem palette(secondary, base);
  }
}

.resultCard {
  width: 19rem;
  margin: 0;
  height: 22rem;
  min-height: 17rem;
  box-shadow: $shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $cardRadius;
  margin: 0.8rem;

  div {
    width: 100%;
    height: 100%;
  }

  .cardHeader {
    height: 20%;
    width: 100%;
    background: palette(secondary, base);
    border-radius: $cardRadius $cardRadius 0 0;
    @include textColor(palette(secondary, base));
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      padding-left: 1rem;
      font-weight: bold;
      font-size: 1.3rem;
    }
  }

  .cardResults {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 80%;
    min-width: 165px;
    width: 100%;
    position: relative;

    p {
      font-size: 1.2rem;
      text-align: center;
      transition: 500ms ease-in all;
    }

    ul.votes {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      height: 60%;
      list-style: none;
      padding: 0;

      li {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 0.4rem;
        position: relative;
      }

      .count {
        font-size: 1.2rem;
        width: auto;
        text-align: right;
      }

      .emotion {
        width: 70%;
        font-size: 1.7rem;
        margin-left: 2rem;
      }
    }

    .separator {
      width: 100%;
      height: 30%;
      align-self: flex-end;
      border-top: 2px solid palette(secondary, base);
      color: palette(black, base);
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
      font-weight: bold;
      font-size: 1.2rem;
      letter-spacing: 0.1rem;
    }

    .iconFail {
      position: absolute;
      top: 1rem;
      width: 10rem;
    }
  }
}
