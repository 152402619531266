@import './variables';
@import './functions';
@import './mixins';

.header {
  background-color: palette(primary, base);
  grid-area: Header;
  font-family: $fontTitles;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  @include textColor(palette(primary, base));

  @media screen and (max-width: 768px) {
    justify-content: center;
    padding: 1rem;
    text-align: center;
  }
  @media screen and (min-width: 769px) {
    justify-content: flex-start;
    padding: 0 2rem;
    text-align: left;
  }

  h1 {
    font-size: 1.8rem;
  }
}
