@import './variables';
@import './functions';
@import './mixins';

.handPlay,
.handHome {
  grid-area: Hand;
  background-color: palette(primary, light);
  @include textColor(palette(primary, light));
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;

    &.hasPlayed {
      .handCard {
        transition: 500ms ease all;
        transition-delay: 500ms;
        height: 0 !important;
        min-height: 0;
        opacity: 0 !important;
        box-shadow: none;
        background: none;
        color: transparent;
      }
    }
  }
  @media screen and (min-width: 769px) {
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;

    &.hasPlayed {
      transition: 500ms ease all;

      .handCard {
        transition: 500ms ease all;
        cursor: default;
        background-color: rgba(palette(primary, dark), 0.3);

        &:hover {
          box-shadow: $HandCardShadowM;

          span {
            padding-left: 1.5rem;
          }

          .virusMini {
            width: 0;
          }

          .arrow {
            width: 0;
          }
        }
      }
    }
  }

  h1 {
    font-family: $fontTitles;
    margin: 0;
  }

  p {
    margin: 0.4rem 0;
  }

  .roundBtn {
    background: palette(secondary, base);
    @include textColor(palette(secondary, base));
    border-radius: $cardRadius;
    outline: none;
    border: none;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
    padding: 0;
    margin: 0 0.5rem;

    svg {
      width: 75%;

      &#rightArrow {
        padding-left: 0.2rem;
        padding-top: 0.2rem;
      }
    }
  }
}

.handPlay {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.handHome {
  padding: 1rem;
}
