@import './variables';
@import './functions';
@import './mixins';
@import './ActionSection.scss';
@import './Board.scss';
@import './Cards.scss';
@import './CookieBanner.scss';
@import './Credits.scss';
@import './ErrorBanner.scss';
@import './Hand.scss';
@import './Header.scss';
@import './Info.scss';
@import './RoundCard.scss';
@import './UserOptions.scss';

body {
  margin: 0;
}

.App {
  display: grid;
  justify-items: stretch;
  font-family: $fontText;
  background-color: palette(grey, xx-light);

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-areas:
      'Info'
      'Header'
      'Hand'
      'Board';
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100vw;
    min-height: 100vh;
  }
  @media screen and (min-width: 769px) {
    grid-template-areas:
      'Header Info'
      'Board Hand';
    grid-template-rows: 15vh minmax(85vh, auto);
    grid-template-columns: 70vw 30vw;

    height: 100vh;
  }

  p {
    margin: 0;
  }
}

.AppHome {
  display: grid;
  justify-items: stretch;
  font-family: $fontText;
  background-color: palette(grey, xx-light);

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-areas:
      'Header'
      'Info'
      'Hand'
      'Board';
    grid-template-rows: auto auto auto;
    grid-template-columns: 100vw;
    min-height: 100vh;
  }
  @media screen and (min-width: 769px) {
    grid-template-areas:
      'Header Info'
      'Board Hand';
    grid-template-rows: 15vh 85vh;
    grid-template-columns: 70vw 30vw;

    height: 100vh;
  }
}
