.actionSectionWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  &.hasAction {
    cursor: pointer;
  }

  .qrCode {
    width: 12rem;
    height: 12rem;
  }

  .shareText {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .manualShare {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 1rem;

    input {
      flex-grow: 1;
      margin-bottom: 0.5rem;
    }
  }
}
