.errorBanner {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: $fontTitles;

  p {
    margin: 0;
    padding: 1rem 2rem;
    min-width: 50%;
    background-color: palette(secondary, base);
    @include textColor(palette(secondary, base));
    text-align: center;
    font-weight: bold;
  }
}
